"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TechnologyStorage_1 = require("../technologies/TechnologyStorage");
const items = require("./items.json");
const imgCoursera = require("../../assets/images/certificates/coursera.png");
const imgUdemy = require("../../assets/images/certificates/udemy.png");
const imgOracle = require("../../assets/images/certificates/oracle-java-8.png");
const imgHackerRank = require("../../assets/images/certificates/hacker-rank.png");
const imgGitHubAdvancedSecurity = require("../../assets/images/certificates/github-advanced-security.png");
const imgGitHubActions = require("../../assets/images/certificates/github-actions.png");
const imgGitHubFoundations = require("../../assets/images/certificates/github-foundations.png");
const imgGitHubAdmin = require("../../assets/images/certificates/github-admin.png");
const imgPracticalDevSecOps = require("../../assets/images/certificates/practical-devsecops-cdp.png");
const imgGitHubCopilot = require("../../assets/images/certificates/github-copilot.png");
const map = new Map([
    ['MWE43H8YDS', imgCoursera],
    ['W5AB2PBZQNPY', imgCoursera],
    ['UC-JGDVYKAQ', imgUdemy],
    ['689b9d7e-cae5-4e36-8a1f-c8c34f104249', imgOracle],
    ['F75CDD8ED9F0', imgHackerRank],
    ['24722d5e-1738-4cec-808b-b4b83a94a8ad', imgGitHubAdvancedSecurity],
    ['711c8e30-13e7-499e-aad6-5da9d468177a', imgGitHubActions],
    ['6f36f952-612a-4aa1-8a3d-adb29396c967', imgGitHubFoundations],
    ['a1c1ec77-29db-4139-8fcb-ac95408653e8', imgGitHubAdmin],
    ['9f12275d-a5b5-4d4d-9127-c975c555f0ab', imgPracticalDevSecOps],
    ['55ddd8bd-950d-45bb-ad25-d08269bc9ca1', imgGitHubCopilot]
]);
class CertificatesStorage {
    _certificates;
    _technologyStorage;
    constructor() {
        this._technologyStorage = new TechnologyStorage_1.default();
        this._certificates = items
            .sort((a, b) => {
            let res = a.date.localeCompare(b.date);
            if (res !== 0) {
                return -res;
            }
            res = a.issuer.localeCompare(b.issuer);
            if (res !== 0) {
                return -res;
            }
            return -b.id.localeCompare(a.id);
        })
            .map((json) => ({
            id: json.id,
            date: new Date(json.date),
            issuer: (json.issuer === 'github' ? 'GitHub' : json.issuer).replaceAll('-', ' '),
            technology: this._technologyStorage.findByName(json.technology),
            url: json.url,
            img: map.get(json.id)
        }));
    }
    get certificates() {
        return this._certificates;
    }
}
const storage = new CertificatesStorage();
exports.default = storage;
