"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ErrorBlock;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
function ErrorBlock({ children, title }) {
    return (React.createElement("div", { className: "error" },
        React.createElement(react_bootstrap_1.Container, { className: "text-center" },
            React.createElement(react_bootstrap_1.Row, { className: "d-flex justify-content-center" }, title),
            React.createElement(react_bootstrap_1.Row, { className: "d-flex justify-content-center" }, children))));
}
