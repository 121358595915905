"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationItem;
const React = require("react");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
const react_i18next_1 = require("react-i18next");
function EducationItem({ universityKey, universityName, location, img, fromYear, toYear }) {
    const { t, i18n } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: `timeline__title` },
            React.createElement("img", { src: img, alt: universityName, loading: "lazy" }),
            t(`education.university.${universityKey}.title`),
            " (",
            t(`locations.${location}`),
            ")"),
        React.createElement("p", { className: "timeline__subtitle" },
            (0, DateUtils_1.toDateLocale)(fromYear),
            " - ",
            (0, DateUtils_1.toDateLocale)(toYear),
            t('comma'),
            (0, DateUtils_1.humanize)(new Date(fromYear, 0), new Date(toYear, 0), i18n.language)),
        React.createElement("div", { className: "timeline__body" }, t(`education.university.${universityKey}.speciality`))));
}
