"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationItem;
const React = require("react");
const I18nService_1 = require("../../scripts/i18n/I18nService");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
const react_i18next_1 = require("react-i18next");
function EducationItem({ universityKey, universityName, location, img, fromYear, toYear }) {
    const getClazzName = (code) => `font-${code === 'jp' ? '' : 'non-'}jp`;
    const { t, i18n } = (0, react_i18next_1.useTranslation)();
    const [clazz, setClazz] = React.useState(getClazzName(i18n.language));
    I18nService_1.default.registerCallback(I18nService_1.I18nServiceCallbackTypes.ON_CHANGED, (code) => setClazz(getClazzName(code)));
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: `timeline__title ${clazz}` },
            React.createElement("img", { src: img, alt: universityName }),
            t(`education.university.${universityKey}.title`),
            " (",
            t(`locations.${location}`),
            ")"),
        React.createElement("p", { className: "timeline__subtitle" },
            (0, DateUtils_1.toDateLocale)(fromYear),
            " - ",
            (0, DateUtils_1.toDateLocale)(toYear),
            t('comma'),
            (0, DateUtils_1.humanize)(new Date(fromYear, 0), new Date(toYear, 0), i18n.language)),
        React.createElement("div", { className: "timeline__body" }, t(`education.university.${universityKey}.speciality`))));
}
