"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationMain;
const React = require("react");
const imgOSAR = require("../../assets/images/education/osar.png");
const SectionTitle_1 = require("../Controls/SectionTitle");
const EducationItem_1 = require("./EducationItem");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function EducationMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "education", className: "light-component" },
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(SectionTitle_1.default, null, t('education.title')),
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement("ul", { className: "timeline" },
                    React.createElement("li", null,
                        React.createElement(EducationItem_1.default, { universityKey: "osar", universityName: "Odesa State Academy of Refrigeration", location: "odesa", img: imgOSAR, fromYear: 2005, toYear: 2010 })))))));
}
