"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsLeetcode;
const React = require("react");
const LoadingSpinner_1 = require("../LoadingSpinner");
const hooks_1 = require("./hooks");
const StatsCommon_1 = require("./StatsCommon");
const LeetcodeService_1 = require("../../scripts/services/LeetcodeService");
function StatsLeetcode({ totalSolved = 238, easySolved = 129, mediumSolved = 106, hardSolved = 3 }) {
    const { data, isLoading } = (0, hooks_1.useFetchStats)(() => new LeetcodeService_1.LeetcodeService({ totalSolved, easySolved, mediumSolved, hardSolved }));
    if (isLoading) {
        return React.createElement(LoadingSpinner_1.default, null);
    }
    return (React.createElement(StatsCommon_1.default, { techName: "leetcode", url: "https://leetcode.com/fabasoad" },
        "\u2714\uFE0F ",
        data.totalSolved));
}
