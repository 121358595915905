"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterMain;
const React = require("react");
const FooterContacts_1 = require("./FooterContacts");
const FooterInfo_1 = require("./FooterInfo");
const FooterSocial_1 = require("./FooterSocial");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_i18next_1 = require("react-i18next");
function FooterMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { className: "footer" },
        React.createElement("div", { id: "contacts", className: "container text-center" },
            React.createElement(SectionTitle_1.default, null, t('contacts.title')),
            React.createElement(FooterContacts_1.default, null),
            React.createElement(FooterSocial_1.default, null),
            React.createElement(FooterInfo_1.default, null))));
}
