"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CertificateItem;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
function CertificateItem({ certificate }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    const { id, img, url, issuer, date } = certificate;
    return (React.createElement(react_bootstrap_1.Card, null,
        React.createElement(react_bootstrap_1.Card.Body, { className: "d-flex flex-column" },
            React.createElement("div", { className: "justify-content-center mt-2" },
                React.createElement(react_bootstrap_1.Card.Img, { src: img })),
            React.createElement(react_bootstrap_1.Card.Title, null,
                React.createElement(react_bootstrap_1.Card.Link, { target: "_blank", rel: "noopener noreferrer", href: url }, t(`certificates.list.${id}`))),
            React.createElement(react_bootstrap_1.Card.Subtitle, null, t('by-issuer', { issuer }))),
        React.createElement(react_bootstrap_1.Card.Footer, null, (0, DateUtils_1.toDateLocale)(date.getFullYear(), date.getMonth()))));
}
