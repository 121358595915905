"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Resume;
const React = require("react");
const SectionTitle_1 = require("./Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function Resume() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "resume", className: "light-component" },
        React.createElement(SectionTitle_1.default, null, t('resume.title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Col, { className: "text-center" },
                React.createElement(react_i18next_1.Trans, { i18nKey: "resume.body" },
                    "To download my resume please go to my ",
                    React.createElement("a", { href: "https://www.linkedin.com/in/yevhenfabizhevskyi/", target: "_blank", rel: "noreferrer" }, "LinkedIn page"),
                    " and choose More \u2192 Save to PDF")))));
}
