"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchStats = useFetchStats;
const react_query_1 = require("@tanstack/react-query");
function useFetchStats(factory) {
    const service = factory();
    const { data, isLoading } = (0, react_query_1.useQuery)({
        queryKey: [service.constructor.name],
        queryFn: () => service.request()
    });
    return { data, isLoading };
}
