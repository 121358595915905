"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsStackExchange;
const React = require("react");
const LoadingSpinner_1 = require("../LoadingSpinner");
const hooks_1 = require("./hooks");
const StatsCommon_1 = require("./StatsCommon");
function StatsStackExchange({ factory, techName, url, icon }) {
    const { data, isLoading } = (0, hooks_1.useFetchStats)(factory);
    if (isLoading) {
        return React.createElement(LoadingSpinner_1.default, null);
    }
    return (React.createElement(StatsCommon_1.default, { techName: techName, url: url },
        icon,
        " ",
        data.reputation));
}
