"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsMain;
const React = require("react");
const react_i18next_1 = require("react-i18next");
const StatsGitHub_1 = require("./StatsGitHub");
const StatsLeetcode_1 = require("./StatsLeetcode");
const StatsStackOverflow_1 = require("./StatsStackOverflow");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const StatsSuperUser_1 = require("./StatsSuperUser");
function StatsMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "stats" },
        React.createElement(SectionTitle_1.default, null, t('stats.title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement(react_bootstrap_1.Col, { className: "text-center" },
                    React.createElement(StatsLeetcode_1.default, null)),
                React.createElement(react_bootstrap_1.Col, { className: "text-center" },
                    React.createElement(StatsGitHub_1.default, null)),
                React.createElement(react_bootstrap_1.Col, { className: "text-center" },
                    React.createElement(StatsStackOverflow_1.default, null)),
                React.createElement(react_bootstrap_1.Col, { className: "text-center" },
                    React.createElement(StatsSuperUser_1.default, null))))));
}
