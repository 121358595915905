"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ExperienceMain;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const ExperienceStorage_1 = require("../../scripts/experience/ExperienceStorage");
const Section_1 = require("../Controls/Section");
const ExperienceItem_1 = require("./ExperienceItem");
function ExperienceMain() {
    return (React.createElement(Section_1.default, { id: "experience" },
        React.createElement(react_bootstrap_1.Row, null,
            React.createElement("ul", { className: "timeline" }, ExperienceStorage_1.default.items.map((experience, index) => (React.createElement("li", { key: index },
                React.createElement(ExperienceItem_1.default, { experience: experience }))))))));
}
