"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDateLocaleFromDate = toDateLocaleFromDate;
exports.toDateLocale = toDateLocale;
exports.humanize = humanize;
const humanizeDuration = require("humanize-duration");
const DigitConverter_1 = require("../DigitConverter");
const react_i18next_1 = require("react-i18next");
const I18nLanguageCodeTranslator_1 = require("../i18n/I18nLanguageCodeTranslator");
const translate = I18nLanguageCodeTranslator_1.default.getTranslateFunc(I18nLanguageCodeTranslator_1.I18nLanguageCodeFormat.ISO_3166_1_alpha_2, I18nLanguageCodeTranslator_1.I18nLanguageCodeFormat.ISO_639_1);
function toDateLocaleFromDate(date) {
    return toDateLocale(date.getFullYear(), date.getMonth());
}
function toDateLocale(year, monthIndex) {
    const { language, t } = (0, react_i18next_1.getI18n)();
    const converter = new DigitConverter_1.default();
    return language === 'jp'
        ? converter.toDoubleByte(year.toString()) + t('year') + (monthIndex != undefined ? t(`month.${monthIndex}`) : '')
        : (monthIndex != undefined ? t(`month.${monthIndex}`) + ' ' : '') + year;
}
function humanize(from, to, code = 'gb') {
    const opts = {
        delimiter: ' ',
        fallbacks: ['en'],
        language: translate(code),
        largest: 2,
        round: true,
        units: ['y', 'mo']
    };
    const duration = to.getTime() - from.getTime();
    return humanizeDuration(duration, opts);
}
