"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Skills;
/// <reference path='./Skills.d.ts' />
const React = require("react");
const react_query_1 = require("@tanstack/react-query");
const Section_1 = require("./Controls/Section");
const TechnologyStorage_1 = require("../scripts/technologies/TechnologyStorage");
const react_bootstrap_1 = require("react-bootstrap");
const ThemeImage_1 = require("./Controls/ThemeImage");
const LoadingSpinner_1 = require("./LoadingSpinner");
function Skills() {
    const technologyStorage = new TechnologyStorage_1.default();
    const { data, isLoading } = (0, react_query_1.useQuery)({
        queryKey: [technologyStorage.constructor.name],
        queryFn: () => technologyStorage.findBySkill(true)
    });
    if (isLoading) {
        return React.createElement(LoadingSpinner_1.default, null);
    }
    return (React.createElement(Section_1.default, { id: "skills" },
        React.createElement(react_bootstrap_1.Row, null,
            React.createElement(React.Suspense, { fallback: React.createElement(LoadingSpinner_1.default, null) },
                React.createElement(react_bootstrap_1.Col, { className: "skills-list text-center" }, data
                    .map((t) => React.createElement(ThemeImage_1.default, { key: `skill-${t.name}`, className: "m-4", imgLight: t.imgLight, imgDark: t.imgDark, alt: t.name, title: t.title })))))));
}
