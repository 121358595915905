"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ExperienceItem;
const React = require("react");
const I18nService_1 = require("../../scripts/i18n/I18nService");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
const react_i18next_1 = require("react-i18next");
function ExperienceItem({ experience }) {
    const { id, title, locationI18nKey, techStack, img, from, to } = experience;
    const getClazzName = (code) => `font-${code === 'jp' ? '' : 'non-'}jp`;
    const { t, i18n } = (0, react_i18next_1.useTranslation)();
    const [clazz, setClazz] = React.useState(getClazzName(i18n.language));
    I18nService_1.default.registerCallback(I18nService_1.I18nServiceCallbackTypes.ON_CHANGED, (code) => setClazz(getClazzName(code)));
    const renderExperienceBody = (k, body) => {
        // For tests
        return typeof body === 'string' ? body : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `timeline__body__achievements ${clazz}` }, t('experience.achievements')),
            React.createElement("ul", null, body.map((bodyItem, index) => (React.createElement("li", { key: `${k}-${index}` }, bodyItem))))));
    };
    const toDate = to === undefined
        ? t('experience.present')
        : (0, DateUtils_1.toDateLocaleFromDate)(to);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: `timeline__title ${clazz}` },
            React.createElement("img", { src: img, alt: title }),
            title,
            " (",
            t(`locations.${locationI18nKey}`),
            ")"),
        React.createElement("p", { className: "timeline__subtitle" },
            (0, DateUtils_1.toDateLocaleFromDate)(from),
            " - ",
            toDate,
            t('comma'),
            (0, DateUtils_1.humanize)(from, to || new Date(), i18n.language)),
        React.createElement("div", { className: "timeline__body" }, renderExperienceBody(id, t(`experience.body.${id}`, { returnObjects: true }))),
        React.createElement("div", { className: "timeline__tech-stack" },
            React.createElement("div", { className: `timeline__tech-stack__title ${clazz}` }, t('experience.tech-stack')),
            React.createElement("div", null, techStack.join(t('comma'))))));
}
