"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ThemeDropDown;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const ThemeContext_1 = require("../Contexts/ThemeContext");
function ThemeDropDown() {
    const { t } = (0, react_i18next_1.useTranslation)();
    const { theme, setTheme } = (0, ThemeContext_1.useThemeContext)();
    return (React.createElement(react_bootstrap_1.Dropdown, null,
        React.createElement(react_bootstrap_1.Dropdown.Toggle, { bsPrefix: "nav-link dropdown-toggle", variant: null },
            React.createElement("i", { className: "fa fa-solid fa-circle-half-stroke" }),
            t(`themes.${theme}`)),
        React.createElement(react_bootstrap_1.Dropdown.Menu, null, ThemeContext_1.SUPPORTED_THEMES.filter(v => v !== theme).map(v => {
            return (React.createElement(react_bootstrap_1.Dropdown.Item, { onClick: () => setTheme(v), bsPrefix: "nav-link", eventKey: v, key: `dropdown-item-theme-${v}` }, t(`themes.${v}`)));
        }))));
}
