"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const DateUtils_1 = require("./utils/DateUtils");
class TotalExperience {
    static EXPERIENCE_START_DATE = new Date(2010, 2, 1);
    getEndDate;
    constructor(endDateGetter = () => new Date()) {
        this.getEndDate = endDateGetter;
    }
    humanize(code) {
        return (0, DateUtils_1.humanize)(TotalExperience.EXPERIENCE_START_DATE, this.getEndDate(), code);
    }
}
exports.default = TotalExperience;
