"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Skills;
/// <reference path='./Skills.d.ts' />
const React = require("react");
const SectionTitle_1 = require("./Controls/SectionTitle");
const TechnologyStorage_1 = require("../scripts/technologies/TechnologyStorage");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function Skills() {
    const { t } = (0, react_i18next_1.useTranslation)();
    const technologyStorage = new TechnologyStorage_1.default();
    return (React.createElement("div", { id: "skills", className: "light-component" },
        React.createElement(SectionTitle_1.default, null, t('skills.title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement(react_bootstrap_1.Col, { className: "skills-list text-center" }, technologyStorage.findBySkill(true)
                    .map((t) => React.createElement("img", { key: `skill-${t.name}`, className: "m-4", src: t.img, alt: t.name, title: t.title })))))));
}
