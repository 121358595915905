"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const items = require("./items.json");
const imgArka = require("../../assets/images/companies/arka-fss.png");
const imgLohika = require("../../assets/images/companies/lohika.png");
const imgBitcoinCom = require("../../assets/images/companies/bitcoin-com.png");
const imgWbyT = require("../../assets/images/companies/wbyt.png");
const map = new Map([
    ['arka', imgArka],
    ['lohika', imgLohika],
    ['bitcoin-com', imgBitcoinCom],
    ['woven-by-toyota', imgWbyT]
]);
class ExperienceStorage {
    _items;
    constructor() {
        this._items = items
            .sort((a, b) => {
            return b.fromYear - a.fromYear;
        })
            .map(({ id, title, locationI18nKey, techStack, fromYear, fromMonthIndex, toYear, toMonthIndex }) => ({
            id,
            title,
            locationI18nKey,
            img: map.get(id),
            techStack,
            from: new Date(fromYear, fromMonthIndex),
            to: toYear === undefined
                ? undefined
                : new Date(toYear, toMonthIndex === undefined
                    ? 0
                    : toMonthIndex)
        }));
    }
    get items() {
        return this._items;
    }
}
const storage = new ExperienceStorage();
exports.default = storage;
