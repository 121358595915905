"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = LanguagesMain;
const React = require("react");
const Section_1 = require("../Controls/Section");
const LanguageLinkItem_1 = require("./LanguageLinkItem");
const LanguageTextItem_1 = require("./LanguageTextItem");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function LanguagesMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(Section_1.default, { id: "languages" },
        React.createElement(react_bootstrap_1.Row, null,
            React.createElement(react_bootstrap_1.Col, { className: "language-list text-center" },
                React.createElement(LanguageLinkItem_1.default, { code: "jp", text: "JLPT N4", url: "https://drive.google.com/file/d/1QHRRPXiYuOKMmNPeqRf6GQQtyGdBkhQ5/view?usp=sharing" }),
                React.createElement(LanguageTextItem_1.default, { code: "gb", text: t('languages.fluent') }),
                React.createElement(LanguageTextItem_1.default, { code: "ua", text: t('languages.native') })))));
}
