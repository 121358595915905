"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = LoadingSpinner;
const React = require("react");
const ThemeContext_1 = require("./Contexts/ThemeContext");
function LoadingSpinner({ background = 'light', color }) {
    const { theme } = (0, ThemeContext_1.useThemeContext)();
    if (!color) {
        color = theme == 'dark' ? 'light' : 'dark';
    }
    return (React.createElement("div", { className: `loader-${background} d-flex justify-content-center` },
        React.createElement("div", { className: `spinner-border text-${color}`, role: "status" },
            React.createElement("span", { className: "sr-only" }, "Loading..."))));
}
