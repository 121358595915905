"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderList = renderList;
exports.default = AboutMain;
const React = require("react");
const react_i18next_1 = require("react-i18next");
const imgPortraitSm = require("../../assets/images/portrait-sm.png");
const TotalExperience_1 = require("../../scripts/TotalExperience");
const Section_1 = require("../Controls/Section");
const react_bootstrap_1 = require("react-bootstrap");
function renderList(children, level = 0) {
    // For tests
    return typeof children === 'string' ? children : (React.createElement("ul", null, children.map(({ title, list }, index) => (React.createElement("li", { key: `about-me-item-${level}-${index}` },
        title,
        Array.isArray(list) ? renderList(list, level + 1) : React.createElement(React.Fragment, null))))));
}
function AboutMain() {
    const { t, i18n } = (0, react_i18next_1.useTranslation)();
    const totalExperience = new TotalExperience_1.default();
    const list = t(`about-me.list`, {
        returnObjects: true,
        totalExperience: totalExperience.humanize(i18n.language)
    });
    return (React.createElement(Section_1.default, { id: "about-me" },
        React.createElement(react_bootstrap_1.Row, { className: "d-flex justify-content-center" },
            React.createElement(react_bootstrap_1.Col, { md: true, className: "about-photo d-flex justify-content-center" },
                React.createElement("img", { className: "about-photo--portrait img-thumbnail rounded-circle", src: imgPortraitSm, alt: "About me", loading: "lazy" })),
            React.createElement(react_bootstrap_1.Col, { md: { span: 8, offset: 2 } }, renderList(list)))));
}
