"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterSocial;
const React = require("react");
const SocialIcon_1 = require("../Controls/SocialIcon");
function FooterSocial() {
    return (React.createElement("div", { className: "footer-social" },
        React.createElement("ul", { className: "icon-list" },
            React.createElement("li", null,
                React.createElement(SocialIcon_1.default, { url: "https://twitter.com/fabasoad", icon: "fa-x-twitter" })),
            React.createElement("li", null,
                React.createElement(SocialIcon_1.default, { url: "https://github.com/fabasoad", icon: "fa-github" })),
            React.createElement("li", null,
                React.createElement(SocialIcon_1.default, { url: "https://www.linkedin.com/in/yevhenfabizhevskyi/", icon: "fa-linkedin" })),
            React.createElement("li", null,
                React.createElement(SocialIcon_1.default, { url: "https://stackoverflow.com/users/470214/fabasoad", icon: "fa-stack-overflow" })),
            React.createElement("li", null,
                React.createElement(SocialIcon_1.default, { url: "https://dev.to/fabasoad", icon: "fa-dev" })))));
}
