"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Header;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const imgPortrait = require("../assets/images/portrait.png");
const react_i18next_1 = require("react-i18next");
function Header() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "header", className: "header row d-flex flex-md-row-reverse flex-lg-row-reverse align-items-center" },
        React.createElement("div", { className: "header__img-container col-md-5 col-lg-5" },
            React.createElement("img", { src: imgPortrait, className: "mx-lg-auto img-fluid", alt: "Portrait", loading: "lazy" })),
        React.createElement("div", { className: "col-md-7 col-lg-7 pt-1" },
            React.createElement("h1", null,
                React.createElement(react_i18next_1.Trans, { t: t, values: { authorGivenName: t('author.given-name') } }, "header.title")),
            React.createElement("h5", null, t('header.occupation')),
            React.createElement("div", null,
                React.createElement(react_bootstrap_1.Button, { variant: "primary", size: "lg", href: "#about-me" }, t('about-me.title')),
                React.createElement(react_bootstrap_1.Button, { variant: "primary", size: "lg", href: "#experience" }, t('experience.title'))))));
}
