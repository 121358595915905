"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationMain;
const React = require("react");
const imgOSAR = require("../../assets/images/education/osar.png");
const Section_1 = require("../Controls/Section");
const EducationItem_1 = require("./EducationItem");
const react_bootstrap_1 = require("react-bootstrap");
function EducationMain() {
    return (React.createElement(Section_1.default, { id: "education" },
        React.createElement(react_bootstrap_1.Row, null,
            React.createElement("ul", { className: "timeline" },
                React.createElement("li", null,
                    React.createElement(EducationItem_1.default, { universityKey: "osar", universityName: "Odesa State Academy of Refrigeration", location: "odesa", img: imgOSAR, fromYear: 2005, toYear: 2010 }))))));
}
