"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsGitHub;
const React = require("react");
const GitHubService_1 = require("../../scripts/services/GitHubService");
const LoadingSpinner_1 = require("../LoadingSpinner");
const hooks_1 = require("./hooks");
const StatsCommon_1 = require("./StatsCommon");
function StatsGitHub({ starsAmount = 286 }) {
    const { data, isLoading } = (0, hooks_1.useFetchStats)(() => new GitHubService_1.GitHubService(starsAmount));
    if (isLoading) {
        return React.createElement(LoadingSpinner_1.default, null);
    }
    return (React.createElement(StatsCommon_1.default, { techName: "gitHub", url: "https://github.com/fabasoad" },
        "\u2B50\uFE0F ",
        data));
}
