"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Section;
const React = require("react");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function Section({ id, children }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: id, className: "section" },
        React.createElement(SectionTitle_1.default, null, t(`${id}.title`)),
        React.createElement(react_bootstrap_1.Container, null, children)));
}
