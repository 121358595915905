"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const TechnologyStorage_1 = require("../../scripts/technologies/TechnologyStorage");
const ThemeImage_1 = require("../Controls/ThemeImage");
const StatsCommon = ({ techName, url, children }) => {
    const technologyStorage = new TechnologyStorage_1.default();
    const { imgLight, imgDark, name, title } = technologyStorage.findByName(techName);
    return (React.createElement(react_bootstrap_1.Row, { className: "justify-content-center" },
        React.createElement("a", { href: url, target: "_blank", rel: "noreferrer" },
            React.createElement(ThemeImage_1.default, { imgLight: imgLight, imgDark: imgDark, alt: name, title: title })),
        children));
};
exports.default = StatsCommon;
