"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CertificatesMain;
const React = require("react");
const CertificatesStorage_1 = require("../../scripts/certificates/CertificatesStorage");
const Section_1 = require("../Controls/Section");
const CertificateItem_1 = require("./CertificateItem");
const react_bootstrap_1 = require("react-bootstrap");
function CertificatesMain() {
    return (React.createElement(Section_1.default, { id: "certificates" },
        React.createElement(react_bootstrap_1.Row, { xs: 2, sm: 3, md: 4, lg: 6 }, CertificatesStorage_1.default.certificates.map((c) => React.createElement(react_bootstrap_1.Col, { lg: true, className: "mb-2", key: c.id },
            React.createElement(CertificateItem_1.default, { certificate: c }))))));
}
