"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Resume;
const React = require("react");
const Section_1 = require("./Controls/Section");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function Resume() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(Section_1.default, { id: "resume" },
        React.createElement(react_bootstrap_1.Col, { className: "text-center" },
            React.createElement(react_i18next_1.Trans, { t: t, i18nKey: "resume.body" },
                "To download my resume please go to my ",
                React.createElement("a", { href: "https://www.linkedin.com/in/yevhenfabizhevskyi/", target: "_blank", rel: "noreferrer" }, "LinkedIn page"),
                " and choose More \u2192 Save to PDF"))));
}
