"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = LanguagesMain;
const React = require("react");
const LanguageLinkItem_1 = require("./LanguageLinkItem");
const LanguageTextItem_1 = require("./LanguageTextItem");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function LanguagesMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "languages", className: "light-component" },
        React.createElement(SectionTitle_1.default, null, t('languages.title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement(react_bootstrap_1.Col, { className: "language-list text-center" },
                    React.createElement(LanguageLinkItem_1.default, { code: "jp", text: "JLPT N4", url: "https://drive.google.com/file/d/1QHRRPXiYuOKMmNPeqRf6GQQtyGdBkhQ5/view?usp=sharing" }),
                    React.createElement(LanguageLinkItem_1.default, { code: "gb", text: "TOEIC 825", url: "https://drive.google.com/file/d/1nzM9WQqR7PQpHKDiWvkRYtcNbnRW8-OB/view?usp=sharing" }),
                    React.createElement(LanguageTextItem_1.default, { code: "ua", text: t('languages.native') }))))));
}
