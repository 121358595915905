"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThemeContext = exports.SUPPORTED_THEMES = void 0;
exports.updateThemeInHtml = updateThemeInHtml;
exports.updateThemeInLocalStorage = updateThemeInLocalStorage;
exports.initTheme = initTheme;
const react_1 = require("react");
exports.SUPPORTED_THEMES = ['dark', 'light'];
function updateThemeInHtml(v) {
    document.querySelector('html').setAttribute('data-theme', v);
}
function updateThemeInLocalStorage(v) {
    window.localStorage.setItem('theme', v);
}
function initTheme() {
    let theme = window.localStorage.getItem('theme');
    if (theme != null) {
        theme = theme.substring(theme.startsWith('"') ? 1 : 0, theme.length - (theme.endsWith('"') ? 1 : 0));
    }
    if (!exports.SUPPORTED_THEMES.includes(theme)) {
        theme = document.querySelector('html').getAttribute('data-theme');
    }
    if (!exports.SUPPORTED_THEMES.includes(theme)) {
        theme = window.matchMedia('(prefers-color-scheme: dark)').matches
            ? exports.SUPPORTED_THEMES[0] : exports.SUPPORTED_THEMES[1];
    }
    if (!window.localStorage.getItem('theme')
        || !exports.SUPPORTED_THEMES.includes(window.localStorage.getItem('theme'))) {
        updateThemeInLocalStorage(theme);
    }
    if (!document.querySelector('html').getAttribute('data-theme')
        || !exports.SUPPORTED_THEMES.includes(document.querySelector('html').getAttribute('data-theme'))) {
        updateThemeInHtml(theme);
    }
    return theme;
}
const ThemeContext = (0, react_1.createContext)({
    theme: exports.SUPPORTED_THEMES[1],
    // biome-ignore lint/suspicious/noEmptyBlockStatements: Default value
    setTheme: () => { }
});
exports.default = ThemeContext;
const useThemeContext = () => (0, react_1.useContext)(ThemeContext);
exports.useThemeContext = useThemeContext;
