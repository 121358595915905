"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = App;
const react_query_1 = require("@tanstack/react-query");
const React = require("react");
const ThemeContext_1 = require("./Contexts/ThemeContext");
const AboutMain_1 = require("./About/AboutMain");
const BackToTopButton_1 = require("./BackToTopButton");
const Badges_1 = require("./Badges");
const CertificatesMain_1 = require("./Certificates/CertificatesMain");
const EducationMain_1 = require("./Education/EducationMain");
const ExperienceMain_1 = require("./Experience/ExperienceMain");
const FooterMain_1 = require("./Footer/FooterMain");
const Header_1 = require("./Header");
const LanguagesMain_1 = require("./Languages/LanguagesMain");
const MenuMain_1 = require("./Menu/MenuMain");
const Resume_1 = require("./Resume");
const Skills_1 = require("./Skills");
const StatsMain_1 = require("./Stats/StatsMain");
function App() {
    const [theme, setTheme] = React.useState((0, ThemeContext_1.initTheme)());
    const switchTheme = (v) => {
        setTheme(v);
        (0, ThemeContext_1.updateThemeInHtml)(v);
        (0, ThemeContext_1.updateThemeInLocalStorage)(v);
    };
    return (React.createElement(react_query_1.QueryClientProvider, { client: new react_query_1.QueryClient() },
        React.createElement(ThemeContext_1.default.Provider, { value: { theme, setTheme: switchTheme } },
            React.createElement("div", { className: "font-regular" },
                React.createElement(BackToTopButton_1.default, null),
                React.createElement(Header_1.default, null),
                React.createElement(MenuMain_1.default, null),
                React.createElement(AboutMain_1.default, null),
                React.createElement(StatsMain_1.default, null),
                React.createElement(Resume_1.default, null),
                React.createElement(Skills_1.default, null),
                React.createElement(ExperienceMain_1.default, null),
                React.createElement(EducationMain_1.default, null),
                React.createElement(CertificatesMain_1.default, null),
                React.createElement(LanguagesMain_1.default, null),
                React.createElement(Badges_1.default, null),
                React.createElement(FooterMain_1.default, null)))));
}
