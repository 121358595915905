"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterInfo;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const package_json_1 = require("../../../package.json");
const react_i18next_1 = require("react-i18next");
const ThemeContext_1 = require("../Contexts/ThemeContext");
function FooterInfo() {
    const { t } = (0, react_i18next_1.useTranslation)();
    const { theme } = (0, ThemeContext_1.useThemeContext)();
    return (React.createElement("div", { className: "d-flex justify-content-center" },
        React.createElement("ul", { className: "icon-list" },
            React.createElement("li", null,
                React.createElement("img", { src: `https://img.shields.io/static/v1?label=made%20by&message=fabasoad&color=${theme === 'dark' ? 'a7d9c1' : '2c3e50'}&style=for-the-badge&logo=github`, alt: "Made by fabasoad", loading: "lazy" })),
            React.createElement("li", null,
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.bitcoinqrcodemaker.com/?style=bitcoin&address=145HwyQAcv4vrzUumJhu7nWGAVBysX9jJH&prefix=on" },
                    React.createElement("img", { src: `https://img.shields.io/static/v1?label=bitcoin&message=donate&color=${theme === 'dark' ? 'a7d9c1' : 'f7931a'}&style=for-the-badge&logo=bitcoin`, alt: "Donate with Bitcoin", loading: "lazy" }))),
            React.createElement("li", null,
                React.createElement(react_bootstrap_1.Badge, { pill: true, bg: "secondary" }, t('version', { version: package_json_1.version }))))));
}
