"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MenuItem;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function MenuItem({ name, setActiveNavLink }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(react_bootstrap_1.Nav.Link, { onClick: () => setActiveNavLink(`#${name}`), eventKey: `#${name}`, href: `#${name}` }, t(`${name}.title`)));
}
