"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CertificatesMain;
const React = require("react");
const CertificatesStorage_1 = require("../../scripts/certificates/CertificatesStorage");
const CertificateItem_1 = require("./CertificateItem");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function CertificatesMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "certificates", className: "light-component" },
        React.createElement(SectionTitle_1.default, null, t('certificates.title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Row, { xs: 2, sm: 3, md: 4, lg: 6 }, CertificatesStorage_1.default.certificates.map((c) => React.createElement(react_bootstrap_1.Col, { lg: true, className: "mb-2", key: c.id },
                React.createElement(CertificateItem_1.default, { certificate: c })))))));
}
