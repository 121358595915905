"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Badges;
const React = require("react");
const imgDigitalOceanBadge = require("../assets/images/badges/digital-ocean-forest-2021.png");
const imgSupportUkraine = require("../assets/images/badges/support-ukraine.png");
const Section_1 = require("./Controls/Section");
const react_bootstrap_1 = require("react-bootstrap");
function Badges() {
    return (React.createElement(Section_1.default, { id: "badges" },
        React.createElement(react_bootstrap_1.Row, null,
            React.createElement(react_bootstrap_1.Col, { sm: true, md: true, lg: true, className: "text-center" },
                React.createElement("a", { href: "https://standforukraine.com", rel: "noopener noreferrer", target: "_blank" },
                    React.createElement("img", { className: "mb-4 mx-4", src: imgSupportUkraine, alt: "Support Ukraine", title: "Stand With Ukraine", loading: "lazy" }))),
            React.createElement(react_bootstrap_1.Col, { sm: true, md: true, lg: true, className: "text-center" },
                React.createElement("a", { href: "https://tree-nation.com/profile/digitalocean", rel: "noopener noreferrer", target: "_blank" },
                    React.createElement("img", { className: "rounded-circle mb-4 mx-4", src: imgDigitalOceanBadge, alt: "Digital Ocean Forest Badge", title: "DigitalOcean Forest 2021", loading: "lazy" }))))));
}
