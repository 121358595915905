"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ExperienceMain;
const React = require("react");
const react_i18next_1 = require("react-i18next");
const react_bootstrap_1 = require("react-bootstrap");
const ExperienceStorage_1 = require("../../scripts/experience/ExperienceStorage");
const SectionTitle_1 = require("../Controls/SectionTitle");
const ExperienceItem_1 = require("./ExperienceItem");
function ExperienceMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "experience", className: "light-component" },
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(SectionTitle_1.default, null, t('experience.title')),
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement("ul", { className: "timeline" }, ExperienceStorage_1.default.items.map((experience, index) => (React.createElement("li", { key: index },
                    React.createElement(ExperienceItem_1.default, { experience: experience })))))))));
}
